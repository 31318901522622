// extracted by mini-css-extract-plugin
export var heading = "ProjectItem-module--heading--bb237";
export var imageFixed = "ProjectItem-module--imageFixed--7e52a";
export var imageFixedWrapper = "ProjectItem-module--imageFixedWrapper--edc22";
export var imageSlicedLeft = "ProjectItem-module--imageSlicedLeft--8e9eb";
export var imageSlicedRight = "ProjectItem-module--imageSlicedRight--8e91d";
export var imagesWrapper = "ProjectItem-module--imagesWrapper--e2471";
export var infoBlock = "ProjectItem-module--infoBlock--4fd97";
export var infoLine = "ProjectItem-module--infoLine--f5e0c";
export var infoLineText = "ProjectItem-module--infoLineText--b838d";
export var infoWrapper = "ProjectItem-module--infoWrapper--9e8fe";
export var isActive = "ProjectItem-module--isActive--f42eb";
export var isMobileVersion = "ProjectItem-module--isMobileVersion--e5ae2";
export var itemWrapper = "ProjectItem-module--itemWrapper--53bf3";
export var projectCounter = "ProjectItem-module--projectCounter--3b084";
export var titleWrapper = "ProjectItem-module--titleWrapper--e6531";