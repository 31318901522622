// extracted by mini-css-extract-plugin
export var bigger = "About-module--bigger--48c6e";
export var content = "About-module--content--96714";
export var contentWrapper = "About-module--contentWrapper--704f4";
export var copyright = "About-module--copyright--75069";
export var highlighted = "About-module--highlighted--439b6";
export var learnMore = "About-module--learnMore--c55be";
export var overlay = "About-module--overlay--11bbd";
export var paragraph = "About-module--paragraph--37987";
export var socialIcon = "About-module--socialIcon--5257b";
export var socialLinks = "About-module--socialLinks--aafe8";