// extracted by mini-css-extract-plugin
export var arrow = "Hero-module--arrow--5f732";
export var arrowIcon = "Hero-module--arrowIcon--c8b6f";
export var arrowIndicator = "Hero-module--arrow-indicator--b37c4";
export var bioSignifier = "Hero-module--bioSignifier--c38e2";
export var description = "Hero-module--description--7e479";
export var descriptionFadeIn = "Hero-module--descriptionFadeIn--d6013";
export var glassyHeadingFixed = "Hero-module--glassyHeadingFixed--50cb6";
export var hero = "Hero-module--hero--5b598";
export var heroReveal = "Hero-module--hero-reveal--5286e";
export var line = "Hero-module--line--6d25f";
export var lineIndicator = "Hero-module--line-indicator--10d05";
export var role = "Hero-module--role--3224e";
export var roleLetter = "Hero-module--roleLetter--85d0f";
export var roleLetterWrapper = "Hero-module--roleLetterWrapper--988bf";
export var scrollDown = "Hero-module--scrollDown--3a0b6";